import DummyImg from '../images/cortex-web-home-banner-min.jpg'
import Dretti from '../images/ctc_emp/cortex-about-dretti-min.png'
import Bugz from '../images/ctc_emp/cortex-about-bugz-min.png'
import JMValino from '../images/ctc_emp/cortex-about-jm-min.png'
// import CNXhome from '../images/CNX Home 01 Trans.png'
// import CTCHome from '../images/cnx-home-full-rs@2x-min.jpg'
// import CTCBiz from '../images/cnx-biz-full-rs@2x-min.jpg'
// import Alvin from '../images/ctc_emp/cortex-about-alvin-min.png'
import Apo from '../images/ctc_emp/cortex-about-apo-min.png'
// import Bianca from '../images/ctc_emp/cortex-about-bianca-min.png'
// import Cha from '../images/ctc_emp/cortex-about-cha-min.png'
import Chylle from '../images/ctc_emp/cortex-about-chyll-min.png'
// import Eman from '../images/ctc_emp/cortex-about-eman-min.png'
import Ferdie from '../images/ctc_emp/cortex-about-ferdie-min.png'
import Froi from '../images/ctc_emp/cortex-about-froi-min.png'
// import Ian from '../images/ctc_emp/cortex-about-ian-min.png'
// import Jerome from '../images/ctc_emp/cortex-about-je-min.png'
// import Kat from '../images/ctc_emp/cortex-about-kat-min.png'
import Maila1 from '../images/ctc_emp/cortex-about-maila-1-min.png'
// import Maila2 from '../images/ctc_emp/cortex-about-maila-2-min.png'
// import Nico from '../images/ctc_emp/cortex-about-nicko-min.png'
// import Rensie from '../images/ctc_emp/cortex-about-rensie-min.png'
// import Ryan from '../images/ctc_emp/cortex-about-ryan-min.png'
import Sean from '../images/ctc_emp/cortex-about-sean-min.png'
// import Val from '../images/ctc_emp/cortex-about-val-min.png'
import Change from '../images/icons/cortex-value-change-min.png'
import Customer from '../images/icons/cortex-value-customer-min.png'
import Future from '../images/icons/cortex-value-future-min.png'
import GoBig from '../images/icons/cortex-value-gobig-min.png'
import Leader from  '../images/icons/cortex-value-leader-min.png'
import Learn from  '../images/icons/cortex-value-learn-min.png'
import NoMan from  '../images/icons/cortex-value-noman-min.png'
import Spending from  '../images/icons/cortex-value-spending-min.png'
import Trigger from  '../images/icons/cortex-value-trigger-min.png'


let dummycontent="Lorem ipsum dolor sit amet, consectetur  adipiscing elit. Cras ullamcorper lectus turpis."

export const article_news =[
    {   id:0,
        title:"Article title",
        date:"November 1, 2019",
        content1:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer egestas tortor vel eleifend malesuada. Aliquam tincidunt volutpat orci id  vehicula. Proin vulputate aliquam tempus. Sed tempus nisl nisl. Curabitur diam velit, maximus sed ipsum nec, hendrerit elementum magna. Sed  sed lacus posuere, tincidunt felis eu, eleifend nibh. Phasellus enim nibh, aliquam non eros sit amet, egestas hendrerit neque. Pellentesque eu  imperdiet dolor. Nullam vulputate maximus nisi, sit amet cursus sapien rhoncus non. Nunc sagittis tellus ut imperdiet vulputate. Ut erat urna,  facilisis non bibendum sit amet, hendrerit a diam. Vestibulum vulputate magna ut arcu laoreet placerat. Integer risus eros, mattis nec fermentum non, hendrerit quis urna. Sed venenatis auctor turpis, vel ornare erat fringilla id. Lorem ipsum dolor sit amet, consectetur adipiscing  elit.",
        author:"Jm Valino",
        auth_img:JMValino, 
        image: DummyImg,
        position:"Head of Iot Operations",
        content2:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer egestas tortor vel eleifend malesuada. Aliquam tincidunt volutpat orci id  vehicula. Proin vulputate aliquam tempus. Sed tempus nisl nisl. Curabitur diam velit, maximus sed ipsum nec, hendrerit elementum magna. Sed  sed lacus posuere, tincidunt felis eu, eleifend nibh."
    },
    {
        id:1,
        title:"Article title",
        date:"August 22, 2019",
        content1:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer egestas tortor vel eleifend malesuada. Aliquam tincidunt volutpat orci id  vehicula. Proin vulputate aliquam tempus. Sed tempus nisl nisl. Curabitur diam velit, maximus sed ipsum nec, hendrerit elementum magna. Sed  sed lacus posuere, tincidunt felis eu, eleifend nibh. Phasellus enim nibh, aliquam non eros sit amet, egestas hendrerit neque. Pellentesque eu  imperdiet dolor. Nullam vulputate maximus nisi, sit amet cursus sapien rhoncus non. Nunc sagittis tellus ut imperdiet vulputate. Ut erat urna,  facilisis non bibendum sit amet, hendrerit a diam. Vestibulum vulputate magna ut arcu laoreet placerat. Integer risus eros, mattis nec fermentum non, hendrerit quis urna. Sed venenatis auctor turpis, vel ornare erat fringilla id. Lorem ipsum dolor sit amet, consectetur adipiscing  elit.",
        author:"Bugz Chavez",
        auth_img: Bugz, 
        image: DummyImg,
        position:"Head of Iot Operations",
        content2:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer egestas tortor vel eleifend malesuada. Aliquam tincidunt volutpat orci id  vehicula. Proin vulputate aliquam tempus. Sed tempus nisl nisl. Curabitur diam velit, maximus sed ipsum nec, hendrerit elementum magna. Sed  sed lacus posuere, tincidunt felis eu, eleifend nibh."
    },
    {
        id:2,
        title:"Article title",
        date:"August 22, 2019",
        content1:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer egestas tortor vel eleifend malesuada. Aliquam tincidunt volutpat orci id  vehicula. Proin vulputate aliquam tempus. Sed tempus nisl nisl. Curabitur diam velit, maximus sed ipsum nec, hendrerit elementum magna. Sed  sed lacus posuere, tincidunt felis eu, eleifend nibh. Phasellus enim nibh, aliquam non eros sit amet, egestas hendrerit neque. Pellentesque eu  imperdiet dolor. Nullam vulputate maximus nisi, sit amet cursus sapien rhoncus non. Nunc sagittis tellus ut imperdiet vulputate. Ut erat urna,  facilisis non bibendum sit amet, hendrerit a diam. Vestibulum vulputate magna ut arcu laoreet placerat. Integer risus eros, mattis nec fermentum non, hendrerit quis urna. Sed venenatis auctor turpis, vel ornare erat fringilla id. Lorem ipsum dolor sit amet, consectetur adipiscing  elit.",
        author:"Dretti Uy",
        auth_img: Dretti, 
        image: DummyImg,
        position:"Head of Iot Operations",
        content2:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer egestas tortor vel eleifend malesuada. Aliquam tincidunt volutpat orci id  vehicula. Proin vulputate aliquam tempus. Sed tempus nisl nisl. Curabitur diam velit, maximus sed ipsum nec, hendrerit elementum magna. Sed  sed lacus posuere, tincidunt felis eu, eleifend nibh."
    }
]

export const ctc_value=[
        {
        id:0,
        icons:Change,
        value_title:"Be an agent of change",
        value_content:"Rather than waiting for it to happen ",
        break:"We actively seek out change."
        },   
        {
        id:1,
        icons:Customer,
        value_title:"Customers come first",
        value_content:"Then the company . . . then the team . . .",
        break:"THEN YOU . . . In every decision and action."
        }, 
        {
        id:2,
        icons:Future,
        value_title:"Don't sell out the future",
        value_content:"A quick win today is shortsighted ",
        break:"We play the long game."
        },     
        {
        id:3,
        icons:GoBig,
        value_title:"Go big or go home",
        value_content:"Think BIG . . . add a few zeroes ",
        break:"Change the world."
        },      
        {
        id:4,
        icons:Leader,
        value_title:"Everyone is a leader",
        value_content:"Leadership isn’t about titles or positions ",
        break:"It’s about influencing others."
        },    
        {
        id:5,
        icons:Learn,
        value_title:"Learning is forever",
        value_content:"You must be your own teacher ",
        break:"Learning is a life-long responsibility."
        },   
        {
        id:6,
        icons:NoMan,
        value_title:"No man left Behind",
        value_content:"Never leave a fellow contributor without support ",
        break:"We always have each other’s back."
        },  
        {
        id:7,
        icons:Spending,
        value_title:"Smart spending",
        value_content:"Regardless of how successful we will be",
        break:"We will always be economical with our resources."
        }, 
        {
        id:8,
        icons:Trigger,
        value_title:"Pull the trigger",
        value_content:"Thinking fast and deciding fast Is better than analysis paralysis."
        }         
]


export const ctc_people={
    leader:[{
        id:0,
        photo:Sean,
        name:"Sean Bryne",
        position:"Ceo", 
        content:dummycontent
    },
    {
        id:1,
        photo:"",
        name:"Chris Mallion",
        position:"Head of HR",
     
        content:dummycontent
    },
    {
        id:2,
        photo:Chylle,
        name:"Rochylle Amarille",
        position:"Head of IoT",
        content:dummycontent,
    },
    {
        id:3,
        photo:Froi,
        name:"Froilan Rabatan",
        position:"SAVP",
        content:dummycontent,
    },
    {
        id:4,
        photo:Ferdie,
        name:"Ferdie Urd",
        position:"Cfo",
        content:dummycontent,
    }
],
    business:[{

    }],
    development:[{
        id:0,
        photo:Maila1,
        name:"Maila Solomon",
        position:"Lead Developer", 
        content:dummycontent
    },
    {
        id:1,
        photo:Apo,
        name:"John Michael Suarez",
        position:"Mobile Developer",
        content:dummycontent
    },
    {
        id:2,
        photo:JMValino,
        name:"Jose Mari Valino",
        position:"Product Research & Embedded Engr.",
        content:dummycontent,
    },
    {
        id:3,
        photo:"No Photo",
        name:"Andrei Melo",
        position:"Frontend Developer",
        content:dummycontent,
    },
    {
        id:4,
        photo:"No Photo",
        name:"Mark Aethen Agana",
        position:"Development Manager",
        content:dummycontent,
    },



],
    after_sales:[{

    }]
}

