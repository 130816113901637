import React from 'react';
import Layout from '../components/layout';
import ContentAllExceptIndex from '../components/content-all-except-index';
import '../components/layout.css';
// import ContentSnd from '../components/content-snd'
import SEO from '../components/seo';
import Footer from '../components/footer';
import GlobalCTA from '../components/globalcta';
import ContentThd from '../components/content-thd';
import Segment from '../images/cortex-global-segment.svg';
import ContentSnd from '../components/content-snd';
import { ctc_value } from '../components/data';
import ContentFth from '../components/content-fth';
// import { ctc_people } from '../components/data';
// import { Link } from 'gatsby';

const About = () => {
	return (
		<Layout>
			<SEO title='Cortex - About' />
			<ContentAllExceptIndex title='about'>
				<div className='cortex-home-content-nd'>
					About us
					<div className='cortex-all-content-except-index-p'>
						<p>
							Cortex Technologies Corporation is a fully owned subsidiary of Concepcion Industrial
							Corporation and was established in 2017 as part of CIC's long-term vision to make innovation
							a core strength of the business.
						</p>
					</div>
				</div>
			</ContentAllExceptIndex>
			<ContentThd>
				<div id='cortex-about-content'>
					<div id='cortex-about-content-mission'>
						<img src={Segment} alt='ctcSegment' />
						<p className='cortex-about-content-p'>Mission</p>
						<p>
							We are dedicated to exploring emerging technology and working with entrepreneurs and
							strategic partners to innovate and commercialize new business models, products, and
							services.
						</p>
					</div>
					<div id='cortex-about-content-vision'>
						<img src={Segment} alt='ctcSegment' />
						<p className='cortex-about-content-p'>Vision</p>
						<p>
							We incubate talent and ideas, giving it the freedom to openly explore avenues of research
							and development and allowing it to partner with entrepreneurs, industry leaders, academe and
							government to produce solutions that can make a real difference in the lives of the everyday
							Filipino.
						</p>
					</div>
				</div>
			</ContentThd>
			<ContentSnd>
				<div id='cortex-about-content'>
					<div id='cortex-about-content-value'>
						<img src={Segment} alt='ctcSegment' />
						<p className='cortex-about-content-p'>Values</p>
					</div>
					<div id='cortex-about-content-value-row'>
						{ctc_value.map((item) => (
							<div id='cortex-about-content-value-row-container'>
								<img src={item.icons} id='cortex-about-content-value-icons' alt='cortexValuesIcons' />
								<div className='cortex-about-content-value-column-container'>
									<p id='cortex-about-content-value-column-p1'> {item.value_title}</p>
									<p id='cortex-about-content-value-column-p2'> {item.value_content}</p>
									<p id='cortex-about-content-value-column-p2'> {item.break}</p>
								</div>
							</div>
						))}
					</div>
				</div>
			</ContentSnd>
			<ContentThd>
				<div id='cortex-about-content'>
					{/* <div id="cortex-about-content-value" style={{border:"none"}}>
                <img src={Segment} alt="ctcSegment"/>
                 <p className="cortex-about-content-p">Life in Cortex</p>
                 <p className="cortex-all-content-except-index-p">Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                   Cras ullamcorper lectus turpis, sit amet vestibulum tellus scelerisque nec.</p>
              </div> */}
				</div>
				<div className='brands1'>
					<div className='cortex-brands1-content'>
						<div className='cortex-brands1-pos'>
							<div className='cortex-brands-content-mission'>
								<p style={{ fontWeight: 'bold', marginTop: 10, fontSize: 24 }}>Careers</p>
								<p>
									Our Talent Event has ended. However, if you still wish to apply, send over your
									resume to career@cortex.ph and we will be glad to get in touch.
								</p>
							</div>
							{/* <div className="cortex-home-content-button">CTA</div> */}
						</div>
					</div>
					<div className='cortex-brands-content-snd-all' />
				</div>
			</ContentThd>
			{/* <ContentSnd>
          <div className="cortex-home-content-snd">
            <div className="cortex-home-content-snd-p">
              <img src={Segment} id="cortex-home-content-segment" alt="cortexSegment"/> 
              <div className="cortex-home-content-title"> Team</div>
              <p className="cortex-home-snd-p">Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                Cras ullamcorperlectus turpis, sit amet vestibulum tellus scelerisque nec. </p>
            </div>
          </div>
          </ContentSnd> */}
			<ContentFth>
				<GlobalCTA />
			</ContentFth>
			<Footer />
		</Layout>
	);
};

export default About;
